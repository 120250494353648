@use '@checkout-ui/design-system' as ds;

.heading {
  margin: 0;

  &.heading-1 {
    @include ds.heading-1;
  }

  &.heading-2 {
    @include ds.heading-2;
  }

  &.heading-3 {
    @include ds.heading-3;
  }
}
