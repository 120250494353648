$unit: 8px;

$spacing-0_5x: ($unit * 0.5);
$spacing-0_75x: ($unit * 0.75);
$spacing-1x: ($unit * 1);
$spacing-1_5x: ($unit * 1.5);
$spacing-2x: ($unit * 2);
$spacing-3x: ($unit * 3);
$spacing-4x: ($unit * 4);
$spacing-5x: ($unit * 5);
$spacing-6x: ($unit * 6);
$spacing-7x: ($unit * 7);
$spacing-8x: ($unit * 8);
$spacing-9x: ($unit * 9);
$spacing-10x: ($unit * 10);
$spacing-11x: ($unit * 11);
$spacing-12x: ($unit * 12);
