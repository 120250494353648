@use '@checkout-ui/design-system' as ds;

.banner {
  padding: ds.$spacing-2x;
  border-radius: ds.$border-radius-4;

  &.default {
    background: ds.$neutral-100;
  }

  &.info {
    background: ds.$info-light;
  }

  &.infoNeutral {
    background: ds.$neutral-50;
    border: 0.5px solid ds.$neutral-200;
    border-radius: ds.$spacing-1x;
  }

  &.error {
    background: ds.$error-light;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: ds.$spacing-1x;
  }
  &__icon {
    margin-right: ds.$spacing-1x;
  }
}
