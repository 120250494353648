@use '@checkout-ui/design-system' as ds;

.container {
  .helper-text {
    padding-top: ds.$spacing-0_5x;
    display: flex;

    span {
      display: inline-flex; // to vertically align icons if passed
      align-items: center;
    }

    svg {
      margin-right: ds.$spacing-1x;
    }

    &--error {
      color: ds.$error-dark;
    }
  }
}
