.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.disc {
    list-style: disc;
    padding: 0 1.5rem; // control disc spacing

    li {
      display: list-item;
    }
  }
}
