@use '@checkout-ui/design-system' as ds;

.list-item {
  padding-bottom: ds.$spacing-3x;

  &__dense {
    padding: 0;
  }

  &__divider {
    &:not(:last-child) {
      border-bottom: 1px solid ds.$neutral-100;
    }
  }

  &.display-flex {
    display: flex;
  }
  &.display-block {
    display: block;
  }
  &.display-none {
    display: none;
  }
  &.align-center {
    align-items: center;
  }
  &.align-flex-start {
    align-items: flex-start;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
}
