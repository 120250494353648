@font-face {
  font-family: fira-sans;
  src: url('fonts/FiraSans/FiraSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: fira-sans;
  src: url('fonts/FiraSans/FiraSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

// FONT FAMILY
$font-primary: 'fira-sans';
$font-demoOnly: 'Poppins';

// FONT WEIGHT
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

// FONT SIZE
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;

// LINE HEIGHT
$line-height-48: 48px;
$line-height-36: 36px;
$line-height-32: 32px;
$line-height-28: 28px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-16: 16px;

// LETTER SPACING
$letter-spacing-0: 0px;
$letter-spacing-2: 0.2px;
$letter-spacing-4: 0.4px;
$letter-spacing-6: 0.6px;
$letter-spacing-8: 0.8px;
$letter-spacing-12: 1.2px;

// MIXIN
@mixin body-1 {
  font-weight: $font-weight-regular;

  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: $letter-spacing-2;
}

@mixin body-2 {
  font-weight: $font-weight-regular;

  font-size: $font-size-14;
  line-height: $line-height-20;
  letter-spacing: $letter-spacing-2;
}

@mixin caption {
  font-weight: $font-weight-regular;

  font-size: $font-size-12;
  line-height: $line-height-20;
  letter-spacing: $letter-spacing-2;
}

@mixin heading-1 {
  font-weight: $font-weight-semi-bold;

  font-size: $font-size-32;
  line-height: $line-height-36;
  letter-spacing: $letter-spacing-2;
}

@mixin heading-2 {
  font-weight: $font-weight-semi-bold;

  font-size: $font-size-22;
  line-height: $line-height-32;
  letter-spacing: $letter-spacing-2;
}

@mixin heading-3 {
  font-weight: $font-weight-regular;

  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: $letter-spacing-2;
}

@mixin button {
  font-weight: $font-weight-semi-bold;

  font-size: $font-size-16;
  line-height: $line-height-24;
  letter-spacing: $letter-spacing-2;
}

@mixin input {
  font-weight: $font-weight-regular;

  font-size: $font-size-16;
  line-height: $line-height-24;
}

@mixin demoOnly {
  font-family: $font-demoOnly;
}
