@use '@checkout-ui/design-system' as ds;

.configuration-flows {
  max-width: 408px;

  &__description {
    margin-bottom: ds.$spacing-3x;
  }

  &__risk-check-name {
    margin-bottom: ds.$spacing-3x;
    &__text {
      @include ds.body-1;
    }
  }

  &__info-banner {
    width: 100%;
    box-sizing: border-box;
    margin-top: ds.$spacing-9x;
    margin-bottom: ds.$spacing-3x;
  }
}
