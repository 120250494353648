@use 'sass:meta';
@use '@checkout-ui/design-system' as ds;
@use '../global-styles/colors' as colors;

.text {
  // to enable using the text component nested in other components
  // example: <Input><Text>something</Text></Input>
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  margin: 0;

  //-------- DO NOT CHANGE the order of the classes to allow using secondary font and override font weight
  // both font weight and family change the font family , for that reason we want font weight to override font family if specified

  &.inherit-font-family {
    font-family: inherit;
  }
  &.primary-font-family {
    font-family: ds.$font-primary;
  }

  //font weight
  &.regular {
    font-weight: ds.$font-weight-regular;
  }

  &.bold {
    font-weight: ds.$font-weight-500;
  }

  &.semi-bold {
    font-weight: ds.$font-weight-semi-bold;
  }

  //--------end of DO NOT CHANGE block

  //color
  &.text--inherit {
    color: inherit;
  }
  &.grey--dark {
    color: ds.$neutral-600;
  }
  &.grey--medium {
    color: ds.$neutral-700;
  }
  &.grey--darker {
    color: ds.$neutral-900;
  }
  &.text--black {
    color: ds.$neutral-900;
  }
  &.text--info {
    color: ds.$info-dark;
  }

  &.error--dark {
    color: ds.$error-dark;
  }

  @each $color, $value in meta.module-variables(colors) {
    &.#{$color} {
      color: $value;
    }
  }
}
