/* Breakpoints*/
// for modal widget inside the Klarna modal frame that we do not own
$width-xs: 320px;
$width-xs-2: 375px;
$width-sm: 420px;
$width-md: 540px;

//https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
/** Breakpoint mixins **/

// The max width of the modal in direct/hpp
$content-max-width: 460px;

// X-Small devices (portrait phones, less than 576px)
@mixin xs {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// X-Large devices (large desktops, 1200px and up)
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// Custom devices
@mixin customBreakpoint($screen) {
  @media (min-width: #{$screen}px) {
    @content;
  }
}

:export {
  xs: $width-xs;
  xs_2: $width-xs-2;
  sm: $width-sm;
  md: $width-md;

  screen_sm: $screen-sm;
  screen_md: $screen-md;
  screen_lg: $screen-lg;
  screen_xl: $screen-xl;

  content-max-width: $content-max-width;
}
