@use '@checkout-ui/design-system' as ds;

.container {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .content {
    padding: ds.$spacing-4x ds.$spacing-5x 0;

    &__heading {
      margin-top: ds.$spacing-3x;
    }

    &__description {
      margin-bottom: ds.$spacing-4x;
      margin-top: ds.$spacing-1x;
    }
  }

  &__previous-configuration {
    display: flex;
    align-items: center;
    margin-top: ds.$spacing-5x;
    cursor: pointer;

    &__icon {
      align-self: flex-end;
      margin-right: ds.$spacing-1_5x;
    }
  }
}
