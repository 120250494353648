@use '@checkout-ui/design-system' as ds;

.country_select {
  max-height: calc(100vh - 452px);
  overflow-y: auto;

  @include ds.sm {
    max-height: calc(100vh - 404px);
  }
}
