@use '@checkout-ui/design-system' as ds;

.radio {
  display: inline-block;
  vertical-align: middle;

  --transition: ease 100ms;

  &,
  & * {
    box-sizing: border-box;
  }

  &__wrapper {
    position: relative;
    transition: color var(--transition);
  }

  &__input {
    position: absolute;
    z-index: -1;
    height: 0;
    width: 0;
    overflow: hidden;
    display: none; //fix visibility issue on safari

    &:hover {
      + .radio__label {
        &--checked {
          box-shadow: ds.$shadow-4;
          border-radius: ds.$border-radius-10;
        }
      }
    }

    &:focus {
      + .radio__label {
        box-shadow: ds.$shadow-4;
        border-radius: ds.$border-radius-10;
      }
    }
  }

  &__label {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--checked {
      &:hover {
        box-shadow: ds.$shadow-4;
        border-radius: ds.$border-radius-10;
      }
    }
  }
}
