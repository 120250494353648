@use '@checkout-ui/design-system' as ds;

.input-label {
  @include ds.body-2;

  cursor: pointer;
  margin-right: ds.$spacing-1x;
  margin-left: ds.$spacing-1x;

  &--disabled {
    cursor: default;
  }
}
