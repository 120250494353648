@use '@checkout-ui/design-system' as ds;

.btn {
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ds.$border-radius-4;
  border: none;

  @include ds.button;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    box-shadow: ds.$shadow-1;
  }

  &:active {
    box-shadow: none;
  }
}
