@use '@checkout-ui/design-system' as ds;

.link {
  display: inline;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  border: none;
  background: transparent;
  text-decoration: none;

  &:hover {
    color: ds.$accent-1;
  }

  &--underline {
    text-decoration: underline;
  }

  & > svg {
    margin-right: ds.$spacing-1x;
  }

  & > * {
    vertical-align: middle;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
}
