$primary: #1e1e1e;
$secondary: #fefefe;

//neutral
$neutral-900: #1e1e1e;
$neutral-800: #525252;
$neutral-700: #636363;
$neutral-600: #6a6a6a;
$neutral-400: #949494;
$neutral-300: #d7dae0;
$neutral-200: #c7c7c7;
$neutral-100: #f3f3f3;
$neutral-50: #fdfdfe;
$neutral-000: #fefefe;

$white: #ffffff;

//error
$error-dark: #a50003;
$error-light: #fff0f0;

//info
$info-dark: #05457b;
$info-light: #e0f2ff;

//accents
$accent-1: #6600f5;
$accent-2: #df1a79;
$accent-3: #ffd000;

//success
$success-light: #e3ffd9;
$success-dark: #1b6100;

:export {
  main: {
    primary: $primary;
    secondary: $secondary;
  }
  neutrals: {
    neutral900: $neutral-900;
    neutral800: $neutral-800;
    neutral700: $neutral-700;
    neutral600: $neutral-600;
    neutral400: $neutral-400;
    neutral300: $neutral-300;
    neutral200: $neutral-200;
    neutral100: $neutral-100;
    neutral50: $neutral-50;
    neutral000: $neutral-000;
  }
  error: {
    errordark: $error-dark;
    errorlight: $error-light;
  }
  info: {
    infodark: $info-dark;
    infolight: $info-light;
  }
  accent: {
    accent1: $accent-1;
    accent2: $accent-2;
    accent3: $accent-3;
  }
  white : {
    white: $white;
  }
}
