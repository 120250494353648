@use '@checkout-ui/design-system' as ds;

.radio-icon__circle--big--selected {
  fill: ds.$primary;
}

.radio-icon__circle--small--selected {
  fill: ds.$neutral-100;
}

.radio-icon__circle--big--unselected {
  stroke: ds.$neutral-900;
}
