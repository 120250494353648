@use '@checkout-ui/design-system' as ds;

@mixin overlay-shadow {
  border: 1px solid ds.$neutral-100;
  box-sizing: border-box;
  box-shadow: ds.$shadow-1;
  border-radius: ds.$border-radius-4;
}

.overlay {
  @include overlay-shadow;

  z-index: ds.$z-index-top;
  position: absolute;
  background-color: ds.$neutral-000;
  width: 100%;

  &--hidden {
    z-index: ds.$z-index-base;
    display: none;
  }
}
