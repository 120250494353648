@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin fadeIn {
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
