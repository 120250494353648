@use '@checkout-ui/design-system' as ds;

.btn--primary {
  background-color: ds.$primary;
  color: ds.$neutral-000;

  &:disabled {
    background-color: ds.$neutral-100;
  }

  &:active {
    background-color: ds.$neutral-900;
  }
}

.font--primary {
  font-family: ds.$font-primary;
}

.font--inherit {
  font-family: inherit;
}
