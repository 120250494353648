@use '@checkout-ui/design-system' as ds;

.input-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: ds.$spacing-6x;
  background: ds.$neutral-000;
  border: 1px solid ds.$neutral-400;
  border-radius: ds.$border-radius-4;

  &_adornment {
    margin: 0 ds.$spacing-2x;
    display: inline-flex; // to vertically align icons if passed
  }

  &_start-adornment {
    margin-right: ds.$spacing-1x; // total - input padding
  }

  &_end-adornment {
    margin-left: ds.$spacing-1x; // total - input padding
  }

  &--focused,
  &--active,
  &:hover {
    border: 1px solid ds.$accent-1;
  }

  &--error {
    border: 1px solid ds.$error-dark;
  }
}
