@use '@checkout-ui/design-system' as ds;

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    margin: 0;

    &_text {
      margin-bottom: ds.$spacing-0_5x;
    }
  }
}
