@use '@checkout-ui/design-system' as ds;

.input-element {
  @include ds.input;

  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0 ds.$spacing-1x;
  display: flex;
  align-items: center;

  &--primary {
    font-family: ds.$font-primary;
  }

  &:not(&--editable) {
    cursor: pointer;
    user-select: none;
  }

  //------- copy from MUI
  border: 0;
  color: ds.$neutral-900;
  background: none;

  -webkit-tap-highlight-color: transparent;

  // Make the flex item shrink with Firefox
  min-width: 0;

  &:focus {
    outline: 0;
  }

  // Reset Firefox invalid required input style
  &:invalid {
    box-shadow: none;
  }

  &::-webkit-search-decoration {
    // Remove the padding when type=search.
    -webkit-appearance: none;
  }
  //------- end of copy from MUI
}
