@use '@checkout-ui/design-system' as ds;

.integration {
  &__country {
    margin: ds.$spacing-2x 0;
  }

  &__language {
    margin-bottom: ds.$spacing-5x;
  }

  &__type {
    .content {
      border: 1px solid ds.$neutral-400;
      border-radius: ds.$border-radius-6;
      padding: ds.$spacing-3x ds.$spacing-3x;
      margin-top: ds.$spacing-2x;
      margin-bottom: ds.$spacing-2x;

      &__item {
        margin-bottom: ds.$spacing-2x;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__cta {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: ds.$spacing-2x ds.$spacing-5x ds.$spacing-4x;
  }
}
