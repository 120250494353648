@use '@checkout-ui/design-system' as ds;
.container {
  display: flex;
  align-items: center;
  .menu {
    position: absolute;
    left: ds.$spacing-2x;
    @include ds.lg {
      display: none;
    }
  }

  .viewport {
    display: block;
    visibility: hidden;
    color: ds.$primary;
    border: 1px solid ds.$neutral-400;
    border-radius: ds.$spacing-0_5x;
    margin: ds.$spacing-0_5x;
    height: ds.$spacing-7x;
    box-sizing: border-box;

    &__placeholder {
      display: block;
      color: ds.$primary;
      margin: ds.$spacing-0_5x;
      height: ds.$spacing-7x;
    }

    @include ds.md {
      display: flex;
      visibility: visible;
    }

    &__item {
      width: 120px;
      padding-bottom: 0;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: ds.$spacing-5x;
        background: ds.$neutral-400;
        position: absolute;
        right: 0;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
      &--desktop {
        display: none;
        @include ds.lg {
          display: flex;
        }
      }
      &--tablet {
        display: none;
        @include ds.md {
          display: flex;
        }
      }
      &--mobile {
        display: none;
        @include ds.md {
          display: flex;
        }
      }
      &--active,
      &:hover {
        background: ds.$primary;
        color: ds.$neutral-000;
        border-color: ds.$primary;
        &:before {
          background: ds.$primary;
        }
      }
      &:hover {
        cursor: pointer;
      }
      span {
        padding-top: ds.$spacing-0_5x;
      }
    }
  }
}
