@use '@checkout-ui/design-system' as ds;

.select-option {
  padding: ds.$spacing-1x;
  min-height: ds.$spacing-5x;
  box-shadow: ds.$shadow-2;

  flex-direction: column;

  &--highlighted,
  &--selected {
    background-color: ds.$neutral-100;
    box-shadow: ds.$shadow-3;
  }
}
