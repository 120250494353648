@use '@checkout-ui/design-system' as ds;

.chevron {
  transition: transform 0.2s;
  color: ds.$neutral-900;

  &--open {
    transform: rotate(-180deg);
  }
}
